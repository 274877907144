import Article from './components/core/Article';
import Accordion from './components/core/Accordion';
import Banner from './components/Banner';
import CardsBlock from './components/CardsBlock';
import FeaturedBrands from './components/FeaturedBrands';
import Header from './components/Header';
import Iframe from './components/core/Iframe';
import IRStockSection from './components/IRStockSection';
import Leadership from './components/Leadership';
import MediaSection3Deep from './components/MediaSection3Deep';
import Navigation from './components/Navigation';
import PageNavigation from './components/PageNavigation';
import PressReleases from './components/PressReleases';
import ProductCarousel from './components/ProductCarousel';
import Profile from './components/Profile';
import Rolodex from './components/core/Rolodex';
import ReportsBlock from './components/ReportsBlock';
import StockInformation from './components/StockInformation';
import SubNavigation from './components/SubNavigation';
import Timeline from './components/Timeline';
import CorporateGovernanceTable from './components/CorporateGovernanceTable';
import withFilter from './higherOrderComponents/withFilter';
import Form from './components/Form';

export const contentTypeToComponentMap = {
  Accordion: {
    component: Accordion,
    hasHeading: true,
  },
  banner: {
    component: Banner,
    hasHeading: true,
  },
  Brand: {
    component: withFilter(FeaturedBrands),
    hasHeading: true,
  },
  Cards: {
    component: withFilter(CardsBlock),
    hasHeading: true,
  },
  Carousel: {
    component: ProductCarousel,
    hasHeading: false,
  },
  Events: {
    component: withFilter(CardsBlock),
    hasHeading: true,
  },
  featuredBrandsComponent: {
    component: FeaturedBrands,
    hasHeading: true,
  },
  Header: {
    component: Header,
    hasHeading: false,
  },
  Leadership: {
    component: withFilter(Leadership),
    hasHeading: true,
  },
  'Media Section - 3 Deep': {
    component: withFilter(MediaSection3Deep),
    hasHeading: true,
  },
  'Menu Links': {
    component: PageNavigation,
    hasHeading: true,
  },
  Navigation: {
    component: Navigation,
    hasHeading: false,
  },
  Form: {
    component: withFilter(Form),
    hasHeading: true,
  },
  News: {
    component: PressReleases,
    hasHeading: true,
  },
  Profile: {
    component: Profile,
    hasHeading: true,
  },
  Reports: {
    component: withFilter(ReportsBlock),
    hasHeading: true,
  },
  Rolodex: {
    component: withFilter(Rolodex),
    hasHeading: true,
  },
  'Rolodex with Title': {
    component: withFilter(Rolodex),
    hasHeading: true,
  },
  'Stock Quote': {
    component: IRStockSection,
    hasHeading: true,
  },
  'Stock Quote Full-Graph': {
    component: StockInformation,
    hasHeading: true,
  },
  SubNavigation: {
    component: SubNavigation,
    hasHeading: false,
  },
  SubscribeForm: {
    component: Iframe,
    hasHeading: true,
  },
  Table: {
    component: CorporateGovernanceTable,
    hasHeading: true,
  },
  'Text Block': {
    component: Article,
    hasHeading: true,
  },
  'Text Block Wide': {
    component: Article,
    hasHeading: true,
  },
  Timeline: {
    component: Timeline,
    hasHeading: false,
  },
  'Upcoming Events': {
    component: withFilter(CardsBlock),
    hasHeading: true,
  },
};

export const remappedComponentItems = (item) => {
  switch (item.sys?.contentType.sys.id) {
    /*
    dbno
    */
    // case 'Leadership': {
    //   const newItem = {
    //     ...item,
    //     items: item.items.map((subitem) => {
    //       return {
    //         categories: subitem.profileCategory,
    //         ...subitem,
    //       };
    //     }),
    //   };
    //   return newItem;
    //   break;
    // }
    case 'Carousel': {
      // console.log('CarouselParser', item);
      const links = item.items.reduce((acc, curr) => {
        return acc.concat(
          curr.items.reduce((acc, curr) => {
            return acc.concat({
              thumbnail:
                curr.mediaItem && curr.mediaItem.thumbnail
                  ? curr.mediaItem.thumbnail
                  : null,
              file:
                curr.mediaItem && curr.mediaItem.file
                  ? curr.mediaItem.file
                  : null,
              ...curr,
            });
          }, [])
        );
      }, []);
      const newCarouselItem = {
        ...item,
        links,
      };
      return newCarouselItem;
    }
    case 'Rolodex with Title':
    case 'Rolodex': {
      const newBrandItem = {
        ...item,
        items: item.components.map((subitem) => {
          return {
            image: subitem.iconImage,
            name: subitem.title,
            ...subitem,
          };
        }),
      };
      return newBrandItem;
    }
    case 'Brand': {
      const newBrandItem = {
        ...item,
        items: item.items.map((subitem) => {
          return {
            categories: subitem.brandReference
              ? subitem.brandReference[0].categories
              : null,
            ...subitem,
          };
        }),
      };
      return newBrandItem;
    }
  }

  return { ...item };
};
